<template>
  <div class="pageContol listWrap templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">结算列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div>
          <el-row :gutter="20" class="mabtm">
            <el-col :sm="12" :md="8" :lg="6" class="searchboxItem">
              <span class="itemLabel">班级编码:</span>
              <el-input
                size="small"
                v-model="projectCode"
                type="text"
                placeholder="请输入班级编码"
                clearable
              />
            </el-col>
            <el-col :sm="12" :md="8" :lg="6" class="searchboxItem">
              <span class="itemLabel">班级名称:</span>
              <el-input
                size="small"
                v-model="projectName"
                type="text"
                placeholder="请输入班级名称"
                clearable
              />
            </el-col>
            <el-col :sm="12" :md="8" :lg="6" class="searchboxItem">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                style="width:100%"
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </el-col>
            <el-col :sm="12" :md="8" :lg="6" class="searchboxItem">
              <span class="itemLabel">机构名称:</span>
              <el-input
                size="small"
                v-model="compName"
                type="text"
                placeholder="请输入机构名称"
                clearable
              />
            </el-col>
            <el-col :sm="12" :md="8" :lg="6" class="searchboxItem">
              <span class="itemLabel">培训类型:</span>
              <el-cascader
                style="width:100%;"
                size="small"
                :props="{
                  emitPath: false,
                  value: 'id',
                  label: 'label',
                  children: 'children',
                  checkStrictly: true,
                }"
                v-model="trainTypeId"
                :options="trainTypeList"
                clearable
                @change="handleTrainType"
              ></el-cascader>
            </el-col>
            <el-col :sm="12" :md="9" :lg="6" class="searchboxItem">
              <span class="itemLabel">开始时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-col>
            <el-col :sm="12" :md="9" :lg="6" class="searchboxItem">
              <span class="itemLabel">结束时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="endTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-col>
            <el-col :sm="12" :md="6" :lg="6" class="df searchboxItem">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="BulkDownload()"
                >导出</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="NotesbatchUpload()"
                >备注批量上传</el-button
              >
            </el-col>
          </el-row>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />

              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                fixed
                prop="projectName"
                width="300px"
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                width="200px"
              />
              <el-table-column
                label="结算单方式"
                align="left"
                show-overflow-tooltip
                prop="billType"
                width="200px"
              >
              <template slot-scope="scope">
                {{scope.row.billType == '10'? '线上': scope.row.billType == '20'?'线下':'' }}
              </template>
              </el-table-column>
              <el-table-column
                label="结算单状态"
                align="left"
                show-overflow-tooltip
                prop="auditTypeName"
                width="200px"
              >
              <template slot-scope="scope">
                {{scope.row.auditTypeName }}
              </template>
              </el-table-column>
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="300px"
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaNamePath"
                width="200px"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeNamePath"
                width="200px"
              />
              <el-table-column
                label="岗位类型"
                align="left"
                show-overflow-tooltip
                prop="postName"
                width="200px"
              >
                <template slot-scope="scope">
                  {{ scope.row.postName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="行业类型"
                align="left"
                show-overflow-tooltip
                prop="industryNamePath"
                width="200px"
              >
                <template slot-scope="scope">
                  {{ scope.row.industryNamePath || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="培训工种"
                align="left"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.occupationNamePath || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="级别"
                align="left"
                show-overflow-tooltip
                width="100"
              >
                <template slot-scope="scope">{{
                  scope.row.trainLevelName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="类别"
                align="left"
                show-overflow-tooltip
                width="100"
              >
                <template slot-scope="scope">{{
                  $setDictionary("SUBSIDYCATEGORY", scope.row.subsidyCategory)
                }}</template>
              </el-table-column>
              <el-table-column
                label="班级总课时"
                align="right"
                show-overflow-tooltip
                prop="totalPeriod"
                width="100"
              />
              <el-table-column
                label="公共课学时"
                align="right"
                show-overflow-tooltip
                prop="publicLessonNum"
                width="100"
              />
              <el-table-column
                label="创建时间"
                align="left"
                width="140px"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.createTime | moment }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="审核通过时间"
                align="left"
                width="140px"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.auditTime | moment }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="开班时间"
                align="left"
                show-overflow-tooltip
                prop="startDate"
                width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.startDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                align="left"
                show-overflow-tooltip
                prop="endDate"
                width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.endDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="计划培训人数"
                align="right"
                show-overflow-tooltip
                prop="projectPeople"
                width="100px"
              />
              <el-table-column
                label="班级学习人数"
                align="right"
                show-overflow-tooltip
                prop="projectStudyNum"
                width="100px"
              />
              <el-table-column
                label="班级完成人数"
                align="right"
                show-overflow-tooltip
                prop="projectCompleteNum"
                width="100px"
              />
              <el-table-column
                label="班级结业人数"
                align="right"
                show-overflow-tooltip
                prop="projectCompleteAllNum"
                width="100px"
              />
              <el-table-column
                label="认证人数"
                align="right"
                show-overflow-tooltip
                prop="authenticNum"
                width="80px"
              />
              <el-table-column
                label="学时分配"
                align="left"
                prop="lessonNumAllocation"
                width="300px"
              />
              <el-table-column
                label="操作"
                align="center"
                width="160px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="seeDetails(scope.row.projectId)"
                    >课程详情</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="editDetails(scope.row.projectId)"
                    >备注</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="财务备注导入"
      :visible.sync="centerDialogVisible"
      width="50%"
      center
      :before-close="doCloseload"
      class="exportdialog"
    >
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span
              @click="downloadExcel"
              style="margin-top:20px;margin-right:20px;color:#409eff;cursor: pointer;"
              >财务备注导入模版</span
            >
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin:20px 0 10px">
              <el-upload
                class="upload-demo upload-workers"
                :action="actionUrl"
                :on-error="handleError"
                :on-success="handleSuccess"
                :on-change="uploadChange"
                :show-file-list="false"
                :auto-upload="false"
              >
                <el-button class="bgc-bv" style="font-size:12px;width: 80%;"
                  >浏览...</el-button
                >
              </el-upload>
              <p v-if="fileName" style="margin-top:10px;margin-left:10px">
                当前选择文件：
                <span style="color:#f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top:10px;margin-left:10px">未选择文件</p>
            </div>
            <div>
              <el-button
                class="bgc-bv"
                size="mini"
                style="margin-top:10px;height: 35px;"
                @click="doExport"
                >开始导入</el-button
              >
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum" style="padding:1rem 0 0 0">
            <span>班级数量:{{ projectCount }}个;</span>
            <span>成功:{{ successCount }}个;</span>
            <span>失败:{{ projectCount - successCount }}个;</span>
          </div>
          <div>
            <el-button
              class="bgc-bv"
              style="margin-top:15px;height: 35px;"
              size="mini"
              :disabled="errorCount == '0'"
              @click="doExportError"
              >导出错误数据</el-button
            >
          </div>
          <div style="margin-top:15px;color:#DD1D35;" v-if="errorCount != 0">
            有导入失败班级，请导出错误数据，将导入失败班级调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "FinanceList",
  components: {
    Empty,
    PageNum,
  },
  data() {
    return {
      projectName: "",
      compName: "",
      trainTypeId: "",
      startTime: [],
      endTime: [],
      trainTypeList: [], // 培训类型
      areaId: "", //
      projectCode: "", //
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      centerDialogVisible: false,
      fileName: "",
      fileKey: "",
      errorCount: "0",
      projectCount: "0",
      successCount: "0",
    };
  },
  mixins: [List],
  created: function() {
    this.getTraintype();
    this.getareatree();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    BulkDownload() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {
            compName: this.compName, // 机构名称
            projectName: this.projectName, // 班级名称
            startDateRange: this.startTime,
            trainTypeId: this.trainTypeId,
            endDateRange: this.endTime,
            projectCode: this.projectCode,
            areaId: this.areaId,
          };
          // this.$post("/run/project/exportExcel", params).then(
          //   (res) => {
          //     if (res.status == "0") {
          //        window.location.href = res.data.url;
          //     }
          //   }
          // );
          this.$post("/biz/settlement/projectremark/exportExcel", params)
            .then((res) => {
              if (res.status == "0") {
                if (!this.downloadItems.includes(res.data.taskId)) {
                  this.$store.dispatch("pushDownloadItems", res.data.taskId);
                } else {
                  this.$message.warning(
                    "[" + res.data.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              } else {
                this.$message.error(re.message);
              }
            })
            .catch(() => {
              return;
            });
        })
        .catch(() => {});
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }

      if (this.startTime && this.startTime.length > 0) {
        params.startDateRange = this.startTime;
      }
      if (this.endTime && this.endTime.length > 0) {
        params.endDateRange = this.endTime;
      }
      this.doFetch({
        url: "/run/project/projectTotalPeriod",
        params,
        pageNum,
      });
    },
    seeDetails(projectId) {
      this.$router.push({
        path: "/web/evaluate/evaluateDetails",
        query: {
          projectId,
        },
      });
    },
    //批量上传
    NotesbatchUpload() {
      this.centerDialogVisible = true;
    },
    downloadExcel() {
      /**
       * 下载模板
       * @param param 参数
       */
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = "/static/结算列表备注批量上传模板.xls";
      link.setAttribute("download", "结算列表备注批量上传模板.xls");
      document.body.appendChild(link);
      link.click();
    },
    // 导入学员
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          // console.log(result);
          this.fileKey = result.data.fileKey;
          // this.fileName = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$confirm("是否导入此文件?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.fileKey) {
              const parmar = {
                key: this.fileKey,
              };
              this.$post(
                "/biz/settlement/projectremark/importExcel",
                parmar,
                5000
              )
                .then((res) => {
                  if (res.status == 0) {
                    this.projectCount = res.data.projectCount;
                    this.errorCount = res.data.errorCount;
                    this.successCount = res.data.successCount;
                    this.url = res.data.url;
                    this.$message({
                      type: "success",
                      message: "导入成功",
                    });
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$message({
                message: "请选择文件",
                type: "warning",
              });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    //导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
         window.open(this.url);
      }
    },
    doCloseload() {
      this.centerDialogVisible = false;
      this.fileKey = "";
      this.fileName = "";
       this.errorCount = "0";
      this.projectCount = "0";
      this.successCount = "0";
      this.getData();
    },
    /* 备注 */
    editDetails(projectId) {
      this.$router.push({
        path: "/web/evaluate/FinaceDetails",
        query: {
          projectId,
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 10;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: function(route) {
      if (route.query.refresh) {
        this.getData(-1);
      }
    },
  },
};
</script>
<style lang="less">
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>
<style lang="less" scoped>
.templateList1 {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    // // .bgc-bv {
    //   // width: 72px;
    // }
    .el-cascader {
      height: 32px !important;
    }
    .itemLabel {
      width: 100px;
    }
  }
}

.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
</style>
